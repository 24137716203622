@font-face {
  font-family: AuctionFont;
  src: url("./assets/font/SourceSansProBold.ttf");
}

.App {
  text-align: center;
  font-family: "sourcesanspro";
  font-style: "normal";
  height: 100vh;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.ButtonAuctionImage {
  max-width: 30px;
  padding-right: 20px;
}

.BoxDescription {
  text-align: left;
  color: white;
  font-size: 18px;
}

.FantaGradient,
.HomePage {
  background: linear-gradient(
    to right,
    rgba(50, 58, 164, 1) 0%,
    rgba(122, 44, 114, 1) 100%
  );
}

.coachCardGrey {
  width: 100%;
  background-color: #eaeaea;
  border-radius: 10px;
}
